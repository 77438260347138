import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, InputNumber, Popover, Select } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

type Props = {
  id: string;
};

type State = {
  generateNumber: number;
  generateStart: number;
  formula: MappingField[];
};

const initialState: State = {
  generateNumber: 0,
  generateStart: 0,
  formula: [],
};

const RowGenWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.generateNumber = state.generateNumber;
          node.data.generateStart = state.generateStart;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [outFsMap, setOutFsMap] = useState<{ [key: string]: MappingField }>({});
  const [transform, setTransform] = useState<string>();

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setGenerateNumber = setStateKeyValue("generateNumber");
  const setGenerateStart = setStateKeyValue("generateStart");
  const setFormula = setStateKeyValue("formula");

  useEffect(() => {
    setTransform(undefined);

    const node: any = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.generateNumber = node.data.generateNumber ?? 0;
          state.generateStart = node.data.generateStart ?? 0;
          state.formula = node.data.formula ?? [];
        }),
      );
    }

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id]);

  const handleMoveAll = () => {
    // Получаем блоки справа
    const find: any = nodes.find((el) => el.id === id);
    const rF: MappingField[] = find?.data.formula;

    let result: MappingField[] = [];
    for (const el of inFs) {
      // Есть слева, есть справа
      if (rF) {
        const rRow = rF.find((z) => z.id === el.id);
        if (rRow) {
          result.push(rRow);
        }
        // Есть слева, нет справа
        if (!rRow) {
          result.push({ ...el, formula: "", uuid: uuidv4() });
        }
      } else {
        result.push({ ...el, formula: "", uuid: uuidv4() });
      }
    }

    setFormula(result);
  };

  useEffect(() => handleMoveAll(), [inFs]);

  useEffect(() => {
    if (state.formula && state.formula.length > 0) {
      setOutFsMap(
        produce((mapping) => {
          state.formula.forEach((f) => {
            mapping[f.uuid] = f;
          });
        }),
      );
    }
  }, [state.formula]);

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Кол-во значений
          </div>
          <InputNumber
            value={state.generateNumber}
            style={{ width: "300px", color: "black" }}
            onChange={(x) => setGenerateNumber(Number(x))}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Стартовое значение
          </div>
          <InputNumber
            value={state.generateStart}
            style={{ width: "300px", color: "black" }}
            onChange={(x) => setGenerateStart(Number(x))}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "320px",
            overflow: "auto",
          }}
        >
          <div style={{ width: "700px" }}>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Входящий/Исходящий поток
            </span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Действие
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.formula?.length > 0 ? (
                  state.formula.map((f: MappingField, i: number) => (
                    <TableRow key={i}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {f.formula && f.formula.length > 0 ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.id || ""}>
                          <Input
                            value={outFsMap[f.uuid]?.id || ""}
                            onChange={R.pipe(
                              R.path(["target", "value"]),
                              makeFormulaUpdater(f.uuid, "id"),
                            )}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.desc || ""}>
                          <Input
                            value={outFsMap[f.uuid]?.desc || ""}
                            onChange={R.pipe(
                              R.path(["target", "value"]),
                              makeFormulaUpdater(f.uuid, "desc"),
                            )}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.type || ""}>
                          <Select
                            style={{ width: "100%" }}
                            value={outFsMap[f.uuid]?.type || ""}
                            onChange={makeFormulaUpdater(f.uuid, "type")}
                            options={__optionType}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Button onClick={() => setTransform(f.uuid)}>🔎</Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет исходящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр фильтра:{" "}
            {state.formula &&
              state.formula.find((el) => el.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log(nodes)}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default RowGenWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
